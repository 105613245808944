import * as React from "react";
import Footer from "../shared/footer/footer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reduxForm } from "redux-form";
import PasswordForm from "./PasswordForm";
import { resetStaffPassword } from "../../api/user";
import { createNotification } from "../../util/notificationManager";
import { ALERT_TYPES } from "../../constants/appConstants";

class ForceChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resetPassword = (data) => {

    if (data.password !== data.confirmPassword) {
      return
    }

    const { history, auth } = this.props;

    resetStaffPassword(data.password, auth.data.data.userId).then((res) => {
      if (res.status) {
        createNotification(ALERT_TYPES.SUCCESS, res.message);
        history.push("/dashboard");
      } else {
        createNotification(ALERT_TYPES.ERROR, res.message);
      }
    });
  };

  onShowConfirmationPassword = () => {
    this.setState({
      showConfirmationPassword: !this.state.showConfirmationPassword
    });
  };

  render() {
    return (

      <div>
        <nav
          className="navbar navbar-expand navbar-white navbar-light main-nav"
          style={{ position: "fixed", top: 0, right: 0, left: 0 }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <img src="./images/logo.svg" alt="" />
              <a className="text-center">
                <span className="navigation-title title">HCBS</span>
              </a>
            </li>
          </ul>
          ;
        </nav>
        <div className="login">
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title">
              <span>Reset Password</span>
            </div>
          </div>
          <PasswordForm handleForm={this.resetPassword} />
        </div>
        ;
        <Footer />;
      </div>);
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default withRouter(connect(mapStateToProps)(ForceChangePassword));
