import * as React from "react";
import { withRouter } from "react-router-dom";
import Header from "../components/shared/header/header";
import SideNavigation from "../components/shared/side-navigation/side-navigation";
import Clients from "../components/clients/clients";
import "./appContainter.scss";
import CreateClient from "../components/clients/create-client/createClient";
import ClientDetails from "../components/clients/client-details/clientDetails";
import Staff from "../components/staff/staff";
import StaffType from "../components/staff/staff-type/staffType";
import CreateStaff from "../components/staff/create-staff/createStaff";
import WorkEntries from "../components/work-entries/workEntries";
import CreateTimeSheet from "../components/work-entries/create-timesheet/createTimeSheet";
import BillingReport from "../components/reports/billingReport";
import Profile from "../components/profile/profile";
import { ProtectedRoute } from "../components/shared/protected-route/protectedRoute";
import StaffDetails from "../components/staff/staff-details/staff-details";
import SupportCoordinator from "../components/support-cordinator/support-cordinator";
import CreateSC from "../components/support-cordinator/create-sc/createSC";
import SCDetails from "../components/support-cordinator/SC-details/SC-details";
import WorkEntryDetails from "../components/work-entries/work-entry-details/workEntryDetails";
import visits from "../components/visits/visits";
import { getAuthData } from "../util/auth";
import VisitStart from "../components/visits/create-visit/visit-init";
import VisitProgress from "../components/visits/visit-progress/visit-progress";
import FAQ from "../components/faq/faq";
import StaffReports from "../components/reports/report-views/staffReports";
import { USER_TYPES } from "../constants/user";
import { ServiceSummary } from "../components/reports/report-views/serviceSummary/serviceSummary";
import Services from "../components/services/services";
import { ImportServices } from "../components/services/services-import/import";
import ForceChangePassword from "../components/login/ForceChangePassword";

class AppContainer extends React.Component {
  render() {
    const userType = JSON.parse(getAuthData()).userType;

    return (
      <div>
        <base href="/" />
        <Header />
        <SideNavigation />
        <div className="content-wrapper app-container">
          <ProtectedRoute exact path="/" component={Dashboard} />

          <ProtectedRoute exact path="/admin/clients" component={Clients} />

          <ProtectedRoute
            exact
            path="/admin/services"
            component={Services}
          />

          <ProtectedRoute
            exact
            path="/admin/import-services"
            component={ImportServices}
          />

          <ProtectedRoute
            exact
            path="/admin/create-client"
            component={CreateClient}
          />
          <ProtectedRoute
            exact
            path="/admin/edit-client"
            component={CreateClient}
          />
          <ProtectedRoute
            exact
            path="/admin/client-details"
            component={ClientDetails}
          />
          <ProtectedRoute exact path="/admin/staff" component={Staff} />
          <ProtectedRoute
            exact
            path="/admin/support-coordinator"
            component={SupportCoordinator}
          />
          <ProtectedRoute
            exact
            path="/admin/staff-type"
            component={StaffType}
          />
          <ProtectedRoute
            exact
            path="/admin/create-staff"
            component={CreateStaff}
          />
          <ProtectedRoute exact path="/admin/create-sc" component={CreateSC} />
          <ProtectedRoute
            exact
            path="/admin/edit-staff"
            component={CreateStaff}
          />
          <ProtectedRoute
            exact
            path="/admin/view-staff"
            component={StaffDetails}
          />
          <ProtectedRoute exact path="/admin/edit-sc" component={CreateSC} />
          <ProtectedRoute exact path="/admin/view-sc" component={SCDetails} />
          <ProtectedRoute
            exact
            path="/admin/work-entries"
            component={WorkEntries}
          />
          <ProtectedRoute
            exact
            path="/admin/create-timeSheet"
            component={CreateTimeSheet}
          />
          <ProtectedRoute
            exact
            path="/admin/edit-timeSheet"
            component={CreateTimeSheet}
          />
          <ProtectedRoute
            exact
            path="/admin/view-timeSheet"
            component={WorkEntryDetails}
          />
          <ProtectedRoute path="/admin/reports" component={BillingReport} />
          {(userType === USER_TYPES.SUPER_ADMIN ||
            userType === USER_TYPES.ADMIN) && (
            <ProtectedRoute exact path="/admin/profile" component={Profile} />
          )}
          {userType === USER_TYPES.STAFF && (
            <ProtectedRoute exact path="/staff/profile" component={Profile} />
          )}
          {(userType === USER_TYPES.SUPER_ADMIN ||
            userType === USER_TYPES.ADMIN) && (
            <ProtectedRoute exact path="/admin/visits" component={visits} />
          )}
          {userType === USER_TYPES.STAFF && (
            <ProtectedRoute exact path="/staff/visits" component={visits} />
          )}
          {userType === USER_TYPES.STAFF && (
            <ProtectedRoute
              exact
              path="/staff/reports"
              component={StaffReports}
            />
          )}
          {userType === USER_TYPES.STAFF && (
            <ProtectedRoute
              exact
              path="/service/reports"
              component={ServiceSummary}
            />
          )}

          <ProtectedRoute
            exact
            path="/staff/start-visit"
            component={VisitStart}
          />
          <ProtectedRoute
            exact
            path="/staff/visit-progress"
            component={VisitProgress}
          />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />

          {(userType === USER_TYPES.SUPER_ADMIN ||
            userType === USER_TYPES.ADMIN) && (
            <ProtectedRoute exact path="/admin/faq" component={FAQ} />
          )}
          {userType === USER_TYPES.STAFF && (
            <ProtectedRoute exact path="/staff/faq" component={FAQ} />
          )}



        </div>
      </div>
    );
  }
}

export default withRouter(AppContainer);

class Dashboard extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    let authData = JSON.parse(getAuthData());
    if (authData.userType === USER_TYPES.STAFF) {
      history.push("/staff/visits");
    } else if (
      authData.userType === USER_TYPES.SUPER_ADMIN ||
      authData.userType === USER_TYPES.ADMIN
    ) {
      // if user is admin or super Admin
      history.push("/admin/clients");
    } else {
      history.push("/dashboard");
    }
  }

  render() {
    return null;
  }
}
