import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import { inputField } from "../../shared/form-fields/formFields";
import { validateEmail } from "../../login/loginValidator";
import OtpInput from "react-otp-input";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class VerifyEmailForm extends React.Component {

  render() {
    const {
      isLinkSent,
      resendToken,
      handleSubmit,
      newOtpSend,
      onFocus,
      enteredOtp,
      onOTPChange,
      wrongOtp,
      sendNewOtp
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        {
          !isLinkSent ?
            <div className="row">
              <div
                className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center">
                <div className="form">
                  <div className="col-12 input-box text-left">
                    <div className="label">Email Address</div>
                    <Field
                      name="email"
                      component={inputField}
                      type="text"
                      placeholder={"email address"}
                      required={true}
                      validate={validateEmail}
                    />
                  </div>
                  <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 offset-sm-0">
                    <button type="submit" className="button submit">
                                            <span className="text">
                                                Send Reset Password Token
                                            </span>
                    </button>
                  </div>
                </div>
              </div>
            </div> :
            <div className="row">

              <div
                className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12 form-container text-center">

                {wrongOtp && (
                  <div
                    className="col-12 reset-token-note"
                    style={{
                      backgroundColor: "#FA7B6F",
                      color: "#FAFBFC"
                    }}
                  >
                    The verification code entered is incorrect. Please try
                    again or request a new code by clicking Resend link below.
                  </div>
                )}

                {sendNewOtp && (
                  <div
                    className="col-12 reset-token-note"
                    style={{
                      backgroundColor: "rgba(53,204,98,0.13)",
                      color: "#35CC62"
                    }}
                  >
                    A new verification code has been sent.
                  </div>
                )}

                <div className="col-12 reset-token-note">
                  We have sent you a password reset token with a token to the registered mobile number. Please enter the
                  token
                  below.
                  If needed
                  <span className="click-here" style={{ cursor: "pointer" }} onClick={resendToken}>
                                        &nbsp;click here&nbsp;
                                    </span>
                  to receive a new token.
                </div>
                <div className="form">
                  <div className="col-12 input-box text-left">
                    <div
                      className="label text-center"
                      style={{
                        color: "#333333",
                        fontSize: 16,
                        marginTop: 16,
                        marginBottom: 16
                      }}
                    >
                      This code is valid only for 10 minutes.
                    </div>
                    <div
                      onFocus={onFocus}
                      style={{
                        display: "flex",
                        paddingTop: 20,
                        paddingBottom: 20,
                        justifyContent: "center",
                        textAlign: "center",
                        alignSelf: "center",
                        alignItems: "center"
                      }}
                    >
                      <OtpInput
                        isInputNum={true}
                        inputStyle={{
                          height: 50,
                          width: 50
                        }}
                        value={enteredOtp}
                        onChange={onOTPChange}
                        numInputs={6}
                        separator={<div style={{ paddingRight: 10 }}></div>}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12">
                    <button type="submit" className="button submit">
                                            <span className="text">
                                                Verify
                                            </span>
                    </button>
                  </div>
                  <div className="col-12 reset-token-note">
                    Didn’t receive a code?
                    <span
                      className="click-here"
                      style={{ cursor: "pointer" }}
                      onClick={newOtpSend}
                    >
                      &nbsp;Resend&nbsp;
                    </span>
                  </div>
                </div>
              </div>
            </div>
        }
      </Form>
    );
  }

}

const VerifyForm = reduxForm({
  form: "verifyEmailForm"
})(VerifyEmailForm);

const mapStateToProps = (state) => ({
  auth: state.auth
});
export default withRouter(connect(mapStateToProps)(VerifyForm));