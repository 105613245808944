import * as React from "react";
import { Field, Form, reduxForm } from "redux-form";
import { inputField } from "../shared/form-fields/formFields";
import { validateChangePassword, validateConfirmPassword, validatePasswordFormat } from "./loginValidator";

class PasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmationPassword: false,
      password: "",
      confirmPassword: ""
    };
  }


  render() {

    const { handleForm } = this.props;

    return (

      <Form onSubmit={(event) => {
        event.preventDefault();
        handleForm({
          password: this.state.password,
          confirmPassword: this.state.confirmPassword
        });
      }}>
        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 form-container text-center">
            <div className="form">
              <div className="col-12 input-box text-left">
                <div className="label">New Password</div>
                <Field
                  name="password"
                  component={inputField}
                  type={this.state.showPassword ? "text" : "password"}
                  placeholder={"password"}
                  required={true}
                  validate={[validateChangePassword, validatePasswordFormat]}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                />
                <i className="text-right icon" onClick={() => {
                  this.setState({
                    showPassword: !this.state.showPassword
                  });
                }}>
                  <img src="./images/eye.svg" alt="" />
                </i>
              </div>
              <div className="col-12 input-box text-left">
                <div className="label">Confirm New Password</div>
                <Field
                  name="confirmationPassword"
                  component={inputField}
                  type={this.state.showConfirmationPassword ? "text" : "password"}
                  placeholder={"password"}
                  required={true}
                  validate={validateConfirmPassword}
                  onChange={(event) => {
                    this.setState({ confirmPassword: event.target.value });
                  }}
                />
                <i className="text-right icon" onClick={() => {
                  this.setState({
                    showConfirmationPassword: !this.state.showConfirmationPassword
                  });
                }}>
                  <img src="./images/eye.svg" alt="" />
                </i>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
                  <button type="submit" className="button submit">
                                            <span className="text">
                                                Change Password
                                            </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}


const resetForm = reduxForm({
  form: "resetPasswordForm"
})(PasswordForm);

export default resetForm;

