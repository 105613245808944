import * as React from "react";
import "./login.scss";
import AuthForm from "./loginForm";
import { withRouter } from "react-router-dom";
import Footer from "../shared/footer/footer";
import { connect } from "react-redux";
import { get, isEqual, isNull } from "lodash";
import { NotificationManager } from "react-notifications";
import { ALERT_TYPES } from "../../constants/appConstants";
import { login } from "../../redux/auth/authAction";

import { getSpecificLoginToken } from "../../util/auth";
import { USER_TYPES } from "../../constants/user";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: false,
      isRememberMe: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { history } = this.props;

    if (
      !isNull(get(nextProps, "auth.data")) &&
      !isEqual(nextProps.auth.data, this.props.auth.data)
    ) {
      let { code: httpStatusCode } = nextProps.auth.data;
      if (httpStatusCode === 201) {
        history.push("/verify-otp");
      } else if (httpStatusCode === 200) {

        if (nextProps.auth.data.data.userType === USER_TYPES.STAFF) {
          history.push("/staff/visits");
        } else if (
          nextProps.auth.data.data.userType === USER_TYPES.SUPER_ADMIN ||
          nextProps.auth.data.data.userType === USER_TYPES.ADMIN
        ) {
          history.push("/admin/clients");
        } else {
          history.push("/dashboard");
        }

        if (nextProps.auth.data.data.passwordResetRequired) {
          history.push("/reset-password");
        }

      }
    }

    if (
      !isNull(get(nextProps, "auth.error")) &&
      !isEqual(nextProps.auth.error, this.props.auth.error)
    ) {
      this.createNotification(
        ALERT_TYPES.ERROR,
        nextProps.auth.error.data.message
      );
    }
  };

  onLogin = (values) => {
    const { dispatch } = this.props;
    const email = values.email.trim().toLowerCase();
    let loginToken = getSpecificLoginToken(email);
    dispatch(login(email, values.password, loginToken));
  };

  onCheckRememberMe = (event) => {
    this.setState({
      isRememberMe: event.target.checked,
    });
  };

  onForgotPassword = () => {
    this.props.history.push("/verify-email");
  };

  createNotification(alertType, alertMessage) {
    switch (alertType) {
      case ALERT_TYPES.INFO:
        NotificationManager.info(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.SUCCESS:
        NotificationManager.success(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.WARNING:
        NotificationManager.warning(alertMessage, "", 2000);
        break;
      case ALERT_TYPES.ERROR:
        NotificationManager.error(alertMessage, "", 2000);
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div>
        <nav
          className="navbar navbar-expand navbar-white navbar-light main-nav"
          style={{ position: "fixed", top: 0, right: 0, left: 0 }}
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <img src="/images/logo.svg" alt="" />
              <a className="text-center">
                <span className="navigation-title title">HCBS</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="login">
          <div className="row">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-12 text-center title">
              <span>Login</span>
            </div>
          </div>
          <AuthForm
            onSubmit={this.onLogin}
            loginError={this.state.loginError}
            checkIsRememberMe={this.onCheckRememberMe}
            onForgotPassword={this.onForgotPassword}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default withRouter(connect(mapStateToProps)(Login));
